<template>
  <div class="home-container">
    <el-tabs v-model="activeTab" @tab-click="handleTabClick" class="middle">
      <el-tab-pane
        v-for="item in tabs"
        :label="item.label"
        :key="item.label"
        :name="item.name"
      >
        <el-form :inline="true" :model="form" class="form">
          <span class="form-label">Filter by:</span>
          <el-form-item label="" label-width="30px" class="form-item year">
            <el-input v-model="form.year" placeholder="Year"></el-input>
          </el-form-item>
          <el-form-item label="" class="form-item quater">
            <el-select v-model="form.quater" placeholder="All Quater">
              <el-option label="Q1" value="1"></el-option>
              <el-option label="Q2" value="2"></el-option>
              <el-option label="Q3" value="3"></el-option>
              <el-option label="Q4" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" class="form-item award">
            <el-select v-model="form.award" placeholder="Superior Award">
              <el-option label="Superior Award" value="1"></el-option>
              <el-option label="Distinctive Award" value="2"></el-option>
              <el-option label="Excellent Award" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch" class="button"
              >Search</el-button
            >
            <el-button type="primary" @click="onReset" class="button"
              >Reset</el-button
            >
          </el-form-item>
        </el-form>

        <el-table
          :data="tableData"
          height="250"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#FF6A64', color: '#ffffff' }"
        >
          <el-table-column prop="cycle" label="Cycle" width="70">
          </el-table-column>
          <el-table-column prop="article" label="Article" width="280">
          </el-table-column>
          <el-table-column prop="author" label="Author" width="100">
          </el-table-column>
          <el-table-column prop="score" label="Score" width="80">
          </el-table-column>
          <el-table-column prop="award" label="Award" width="100">
          </el-table-column>
          <el-table-column fixed="right" label="Edit" width="80">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >Edit</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <div class="customer-info">
      <customerInfo />
    </div>
    <el-dialog
      class="edit-score-modal"
      :visible.sync="editScoreVisible"
        width="560px"
        :modal="true"
        title="Edit Score"
        :modal-append-to-body="true"
      >
      <score-table></score-table>
      <span slot="footer" class="edit-score-footer">
        <el-button type="primary" @click="visible = false">Cancel</el-button>
        <el-button type="primary" @click="handleSubmit">Submit</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import customerInfo from "@/components/customerInfo.vue";
import scoreTable from "../components/scoreTable.vue";
export default {
  name: "home",
  components: {
    customerInfo,
    scoreTable
  },
  data() {
    return {
      articleList: [],
      activeTab: "Campus",
      tabs: [
        { label: "Campus", name: "Campus" },
        { label: "Business", name: "Business" },
        { label: "Literature", name: "Literature" },
        { label: "Thinking", name: "Thinking" },
        { label: "Intellect", name: "Intellect" },
        { label: "Apex", name: "Apex" },
        { label: "History", name: "History" },
        { label: "PAC", name: "PAC" },
      ],
      form: {
        year: new Date().getFullYear(),
        quater: "",
        award: "",
      },
      tableData: [
        {
          cycle: "24Q3",
          article: "xxxx",
          author: "xxxx",
          score: 9.0,
          award: "xxxx",
        },
        {
          cycle: "24Q3",
          article: "xxxx",
          author: "xxxx",
          score: 9.0,
          award: "xxxx",
        },
      ],
      editScoreVisible: false
    };
  },
  methods: {
    handleTabClick(tab, event) {
      console.log(tab, event);
    },
    onSearch() {},
    onReset() {
      this.form = {
        year: new Date().getFullYear(),
        quater: "",
        award: "",
      };
    },
    handleClick(row) {
      this.editScoreVisible = true;
    }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.home-container {
  height: 100%;
  display: flex;

  .article-list {
    flex: 3;
    margin-right: 20px;
  }

  .middle {
    flex: 1;
    margin-right: 20px;
  }
  .form {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &-label {
      margin-right: 20px;
      font-size: 14px;
    }
    &-item {
      width: 80px;
      margin-bottom: 0;
      margin-right: 10px;
    }
    .quater {
      width: 120px;
    }
    .award {
      width: 230px;
    }
    .button {
      margin-bottom: 0;
    }
  }
}
/deep/.el-form-item {
  margin-bottom: 0;
}

/deep/.el-dialog__header {
  text-align: center;
  .el-dialog__title {
    color: #eb7269;
    font-family: GravityBold;
  }
}
/deep/.el-dialog__footer {
  text-align: center;
}
</style>
